import React, { useEffect } from "react";
import { Apiname } from "../Constant/Apiname";
import { fetchApiGet, fetchApiPost } from "../Utils/FetchApi";
import { encode as base64_encode } from "base-64";
import tagManagerEvents from "../Utils/GoogleTagManager";
import Awareness from "../components/Awareness";
import TagManager from "react-gtm-module";

type Props = {
  token: string | null;
  isLanding: boolean;
};
let utmSource: any = "";
let adNetwork: string = "";
const AwarenessScreen = (props: Props) => {
  useEffect(() => {
    if (props.token !== null) {
      const url = new URL(window.location.href);
      const searchParams = new URLSearchParams(url.search);
      utmSource = searchParams.get("utm_source");
      checkUtms();
      getFlow(utmSource);
    }
  }, [props.token]);

  useEffect(() => {
    setTimeout(() => {
      gtmInitialization();
    }, 1000);
  }, []);

  const gtmInitialization = () => {
    const tagManagerArgs = {
      gtmId: "GTM-5LRQ89V",
    };
    TagManager.initialize(tagManagerArgs);
  };

  const checkUtms = async () => {
    try {
      let response = await fetchApiGet(props.token, Apiname.utmList);
      if (response.status === 200) {
        response.response.map((e: any) => {
          if (utmSource === e.sf_utm) {
            adNetwork = e.ad_network;
          }
        });
      }
      // console.warn("adNEtwork", adNetwork);
    } catch (error) {
      console.log("check utm error", error);
    }
  };

  const getFlow = async (utmSource: string | null) => {
    try {
      const endPoint = `${Apiname.awarenessFlow}?utm_source=${utmSource}`;
      const response = await fetchApiGet(props.token, endPoint);

      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          tagManagerEvents("subscribe", "econTikTok");
        } else if (adNetwork.toLowerCase() === "google") {
          tagManagerEvents("subscribe", "econceptions");
        } else {
          tagManagerEvents("subscribe", utmSource);
        }
        navigateToHome(response.msisdn);
      } else if (
        response.status === 1 &&
        response.result.toLowerCase() === "already subscribed"
      ) {
        navigateToHome(response.msisdn);
      } else {
        navigateTolanding();
      }
    } catch (error) {
      navigateTolanding();
    }
  };
  // const subscribe = async (
  //   apiName: string,
  //   _otp: string,
  //   _msisdn: any,
  //   subPackage: any
  // ) => {
  //   try {
  //     const data = {
  //       msisdn: _msisdn,
  //       otp: _otp,
  //       utm_source: utmSource,
  //       subPackage: subPackage,
  //     };

  //     const response = await fetchApiPost(props.token, apiName, data);
  //     if (response.status === 0) {
  //       if (
  //         adNetwork.toLowerCase() === "tiktok" ||
  //         adNetwork.toLowerCase() === "tik tok"
  //       ) {
  //         console.log("a");
  //         tagManagerEvents("subscribe", "econTikTok");
  //       } else if (adNetwork.toLowerCase() === "google") {
  //         console.log("b");
  //         tagManagerEvents("subscribe", "econceptions");
  //       } else {
  //         console.log("c");
  //         tagManagerEvents("subscribe", utmSource);
  //       }
  //       navigateToHome(_msisdn);
  //     } else {
  //       navigateToHome("");
  //     }
  //   } catch (error) {
  //     navigateToHome("");
  //   }
  // };

  const navigateToHome = (msisdn: any) => {
    let url = window.location.origin;
    let encodedMsisdn = base64_encode(msisdn);
    url = `${url}/home?user=${encodedMsisdn}`;
    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    window.location.replace(url);
  };

  const navigateTolanding = () => {
    let url = window.location.origin;
    url = `${url}/landing`;
    window.location.replace(url);
  };
  return (
    <>
      <Awareness />
    </>
  );
};

export default AwarenessScreen;
